import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Jerk (any variation) 5×1 to a 1RM`}</p>
    <p>{`Pendlay Rows 5×2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`50 Double Unders`}</p>
    <p>{`15 S2OH (115/75)`}</p>
    <p>{`40 Double Unders`}</p>
    <p>{`12 S2OH (135/95)`}</p>
    <p>{`30 Double Unders`}</p>
    <p>{`9 S2OH (155/105)`}</p>
    <p>{`20 Double Unders`}</p>
    <p>{`6 S2OH (175/115)`}</p>
    <p>{`10 Double Unders`}</p>
    <p>{`3 S2OH (195/125)`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 6/1/18`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Open wod 19.4 will be announced at 8:00pm tonight at
games.crossfit.com!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility class will from from 11:00-11:30 Saturday, March
16th.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule will start next Monday, March 17th along
with our new Ville Bootcamp Tues-Friday at 9:30am!  Evening CrossFit
classes will now be offered Monday-Friday at 4:00, 5:00 & 6:00pm!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      